<template>
  <div
    class="toast-controller"
    data-cy="toast-controller"
  >
    <div
      v-for="toast in toasts"
      :key="toast.id"
      class="toast"
      :class="[toast.mainClass, {'disappear': toast.fading}]"
      data-cy="toast"
    >
      <!-- Type icon -->
      <div
        data-cy="toast-icon-box"
        class="type-icon-box"
        :class="toast.iconBoxClass"
      >
        <Icon
          data-cy="toast-icon"
          :size="12"
          :name="toast.icon"
        />
      </div>
      <div
        class="toast-text"
        data-cy="toast-text"
      >
        {{ toast.text }}
      </div>
      <Icon
        v-if="!toast.noCancel"
        data-cy="toast-close-icon"
        name="times"
        :size="22"
        class="close-icon"
        @click="removeToast(toast.id)"
      />
    </div>
  </div>
</template>

<script>

import Icon from './Icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    bugsnagClient: {
      type: Object,
      required: true,
    },
  },
  emits: ['interface'],
  data() {
    return {
      toasts: [],
    };
  },
  mounted() {
    this.exposeInterface();
  },
  methods: {
    typeProperties(toast) {
      if (toast.type === 'error') {
        return {
          icon: 'thick-cross',
          iconBoxClass: 'type-icon-box--error',
          mainClass: 'toast--error',
        };
      }

      /* defaults to success */
      return {
        icon: 'thick-checkmark',
        iconBoxClass: 'type-icon-box--success',
        mainClass: 'toast--success',
      };
    },
    removeToast(id) {
      const index = this.toasts.findIndex((toast) => toast.id === id);
      if (index === -1) return;
      setTimeout(() => {
        this.toasts = this.toasts.filter((toast) => toast.id !== id);
      }, 300);
      this.toasts[index].fading = true;
    },
    addToast(config) {
      this.validateToastConfig(config);
      const id = this.generateUniqueId();
      this.toasts.push({
        id,
        ...config,
        ...this.typeProperties(config),
      });
      if (config.duration !== 0) {
        setTimeout(() => {
          this.removeToast(id);
        }, config.duration || 5000);
      }
    },
    validateToastConfig(config) {
      if (config.duration === 0 && config.noCancel) {
        throw new Error('You cannot have a toast with duration 0 and noCancel');
      }
      Object.entries(config).forEach(([key, value]) => {
        const validKeys = ['text', 'type', 'duration', 'noCancel'];
        if (!validKeys.includes(key)) {
          this.bugsnagClient.notify(`Invalid key ${key} in toast config, valid keys are ${validKeys.join(', ')}`, {
            metaData: {
              config,
            },
          });
        }
        if (key === 'duration' && typeof value !== 'number') {
          this.bugsnagClient.notify('Duration must be a number', {
            metaData: {
              config,
            },
          });
        }
        if (key === 'noCancel' && typeof value !== 'boolean') {
          this.bugsnagClient.notify('noCancel must be a boolean', {
            metaData: {
              config,
            },
          });
        }
        if (key === 'type' && value !== 'error' && value !== 'success') {
          this.bugsnagClient.notify('type must be either "error", "success" or undefined', {
            metaData: {
              config,
            },
          });
        }
      });
    },
    generateUniqueId() {
      return Math.random().toString(36).substr(2, 9);
    },
    exposeInterface() {
      this.$emit('interface', {
        add: this.addToast,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/main";

.toast-controller {
  position: fixed;
  top: 16px;
  right: 0;
  z-index: 10100;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  gap: 8px;
}

.toast {
  color: #fff;
  min-width: 300px;
  max-width: 100vw;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, .15);
  display: flex;
  align-items: center;
  pointer-events: auto;
  animation: .2s appear linear forwards;

  &--success {
    background-color: #E4F7F3;
    border: 1px solid rgba(0, 145, 116, .2);
  }

  &--error {
    background-color: $color-semantic-error-light;
    border: 1px solid rgba(212, 49, 79, .2);
  }
}

.toast-text {
  @include font-body-14-medium;
  color: $color-grey-tinted-900;
  flex: 1;
  word-break: break-word;
  max-width: 90ch;
}

.type-icon-box {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;

  &--success {
    background-color: $color-semantic-success;
  }

  &--error {
    background-color: $color-semantic-error;
  }
}

.close-icon {
  color: $color-grey-tinted-500;
  cursor: pointer;
  margin-left: 12px;
}

.disappear {
  animation: .3s disappear linear forwards;
}

@keyframes appear {

  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes disappear {

  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
</style>
