import calendar from './calendar';
import categories from './categories';
import conversations from './conversations';
import settings from './settings';
import services from './services';
import tasks from './tasks';
import partners from './partners';
import ratings from './ratings';
import orders from './orders';

export default [
  {
    path: '/',
    redirect: { name: 'Home' },
  },
  {
    path: '/home',
    name: 'Home',
    component: () =>
      import(
        /* webpackChunkName: "home" */ '@/views/home-v2/pages/home-v2.vue'
      ),
  },
  {
    path: '/marketplace-redirect',
    name: 'MarketplaceRedirect',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/marketplace-redirect.vue'),
  },
  ...calendar,
  ...categories,
  ...conversations,
  ...settings,
  ...services,
  ...tasks,
  ...partners,
  ...ratings,
  ...orders,
];
