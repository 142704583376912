<template>
  <component
    :is="serviceIconComponent"
    v-bind="$props"
    :calculated-icon-size="calculatedIconSize"
    class="service-icon"
    :class="`service-icon--${iconSize}`"
    :is-direct="isDirect"
  />
</template>

<script>
import ServiceIconVerified from './service-icon-verified.vue';
import ServiceIconUnverified from './service-icon-unverified.vue';

export default {
  name: 'ServiceIcon',
  props: {
    servicePlainTextId: {
      type: Number,
      default: null,
    },
    serviceName: {
      type: String,
      default: null,
    },
    iconSize: {
      validator(size) {
        return ['small', 'medium', 'large'].includes(size);
      },
      type: String,
      default: 'medium',
    },
    isVerified: {
      type: Boolean,
      required: true,
    },
    isDirect: {
      type: Boolean,
    },
  },
  computed: {
    serviceIconComponent() {
      return this.isVerified
        ? ServiceIconVerified
        : ServiceIconUnverified;
    },
    calculatedIconSize() {
      const sizes = {
        small: 18,
        medium: 20,
        large: 25,
      };
      return sizes[this.iconSize];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/main";

.service-icon {
  background: $color-grey-tinted-150;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  flex-shrink: 0;
  transition: all .2s;

  &--small {
    height: 32px;
    width: 32px;
    border-radius: 9px;
  }

  &--medium {
    height: 40px;
    width: 40px;
    border-radius: 9px;
  }

  &--large {
    height: 48px;
    width: 48px;
    border-radius: 10px;
  }
}
</style>
