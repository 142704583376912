import api from './api';
import echo from './echo';
import Bugsnag from '@bugsnag/js';

export default function apiplugin(store) {
  if (store.state.auth.access_token) {
    api.defaults.headers.common.authorization = `Bearer ${store.state.auth.access_token}`;
    echo.connector.pusher.config.auth.headers.Authorization = `Bearer ${store.state.auth.access_token}`;
  }

  store.subscribe((mutation) => {
    if (mutation.type === 'users/set_me') {
      Bugsnag.user = {
        id: mutation.payload.id,
        name: mutation.payload.name,
        email: mutation.payload.email,
      };

      if (globalThis.Beacon) {
        if (mutation.payload.id && mutation.payload.email) {
          // we got a user, show Beacon and send user data
          globalThis.Beacon('init', '03c7e398-7eb1-434c-8832-76e350e5e9b3');
          if (!mutation.payload.super_user) {
            globalThis.Beacon('identify', {
              id: mutation.payload.id,
              name: mutation.payload.name,
              email: mutation.payload.email,
              type: 'customer',
              company: mutation.payload.customer?.name,
              created_at: mutation.payload.created_at?.split('T')[0],
              avatar:
                'https://imagedelivery.net/veAPhTcQT2sdfpabQ16DDg/2ea9223d-841c-4bed-d61d-f885d8694600/public',
            });
          }
        } else {
          // no user, hide Beacon
          globalThis.Beacon('destroy');
        }
      }

      api.defaults.params =
        typeof api.defaults.params === 'undefined' ? {} : api.defaults.params;
      api.defaults.params.lang = mutation.payload.lang;
    }
    if (mutation.type === 'auth/updateToken') {
      api.defaults.headers.common.authorization = `Bearer ${mutation.payload}`;
      echo.connector.pusher.config.auth.headers.Authorization = `Bearer ${mutation.payload}`;
    } else if (mutation.type === 'auth/clearToken') {
      delete api.defaults.headers.common.authorization;
      // TODO Also clear echo token
    }
  });
}
