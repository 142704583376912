import { createRouter, createWebHistory } from 'vue-router';
// import { applyPushFix } from '@officeguru/webapp-shared/src/router/apply-push-fix';
import { processTempToken } from '@officeguru/webapp-shared/src/router/process-temp-token';
import { startAuthCheck, stopAuthCheck } from '@/store/plugins/api';
import restrictedRoutes from './routes/restricted';
import publicRoutes from './routes/public';
import store from '../store';
import Restricted from '../layouts/Restricted.vue';
import Public from '../layouts/Public.vue';
import { posthog } from '../plugins/posthog';

// applyPushFix(VueRouter);

// Swallows errors that happen when a route is accessed twice (e.g. by clicking the same link a second time). All other errors are still thrown.
// @TODO: Extract, combine and consume from webapp-shared
/*
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function push(location) {
  return originalReplace.call(this, location).catch((error) => {
    if (error?.name === 'NavigationDuplicated') {
      return;
    }

    throw error;
  });
};
*/
const router = new createRouter({
  history: createWebHistory(),
  base: import.meta.env.BASE_URL,
  /**
   * After scrolling down in one view and changing a route, the browser keeps the scroll position which
   * is not what we want. We therefore put the scroll to 0, 0 ("top" of the page). Bonus: When using
   * the browser's back/forward button, the position is still saved (savedPosition).
   */
  scrollBehavior: (to, from, savedPosition) => {
    if (to.matched.some((record) => record.meta.preventScroll)) {
      return false;
    }
    return savedPosition || { x: 0, y: 0 };
  },
  routes: [
    // Restricted as in 'being logged in'
    {
      component: Restricted,
      path: '/',
      children: restrictedRoutes,
    },
    // Public
    {
      component: Public,
      path: '/public',
      redirect: '/', // don't allow direct access to /public
      children: publicRoutes,
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'Unmatched route',
      beforeEnter() {
        router.replace({ name: 'Home' });
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  // Grab the `temp_token` if available (i.e. when authenticating via the HUB) and authenticate the user with it
  processTempToken(store);

  const isPublicRoute = to.matched.some((record) => record.meta.public);

  if (isPublicRoute) {
    stopAuthCheck();

    // direct route needs to handle on its own
    if (to.name !== 'direct') {
      await store.dispatch('auth/logout');
    }
  }

  // strip a logouts "redirect" if the requested route is login,
  // so that we don't logout on login and have to login twice
  if (
    to.path === '/login' &&
    to.query.redirect &&
    to.query.redirect.startsWith('/logout')
  ) {
    return next({
      path: '/login',
      query: {
        ...to.query,
        redirect: undefined,
      },
    });
  }

  if (store.getters['auth/isAuthenticated']) {
    startAuthCheck();
  } else {
    stopAuthCheck();
  }

  return next();
});

router.beforeResolve((to, from, next) => {
  if (window.location.hash.indexOf('from_iframe') === 1) {
    const ours = [/conversations\/?.*/, /calendar\/?.*/];

    if (from.name === null) {
      return next();
    }

    const isOurs = ours.some((expression) => {
      return (
        expression.test(to.path) ||
        (expression.test(from.path) && expression.test(to.path))
      );
    });

    const toPath = to.matched[0].path;

    if (isOurs) {
      return next();
    }

    window.parent.postMessage(
      { event: 'navigation', path: toPath, params: to.params, query: to.query },
      '*'
    );

    return null;
  }

  return next();
});

router.afterEach(() => {
  posthog.pageview();
});

export default router;
