<template>
  <div
    data-cy="app"
    id="app"
    :class="{ 'navigation-collapsed': navigationHidden }"
  >
    <router-view />
    <Toaster @interface="bindToasterInterface" :bugsnag-client="Bugsnag" />
  </div>
</template>

<script>
window.process = import.meta;

import './style/main.scss';
import {
  registerEmitter,
  emitter,
} from '@officeguru/webapp-shared/src/helpers/emitter';
import api from '@/store/plugins/api';
import { Toaster } from '@officeguru/components-vue3';
import { getCurrentInstance } from 'vue';
import Bugsnag from '@bugsnag/js';
import { posthog } from './plugins/posthog';

export default {
  name: 'App',
  components: {
    Toaster,
  },
  setup() {
    return {
      Bugsnag,
    };
  },
  created() {
    this.toggleMenu();
    getCurrentInstance().appContext.config.globalProperties.$posthog = posthog;
    emitter.on('resize', () => {
      this.toggleMenu();
    });
  },
  provide: {
    api,
  },
  methods: {
    bindToasterInterface(toastInterface) {
      getCurrentInstance().appContext.config.globalProperties.$toast = toastInterface;
    },
    toggleMenu() {
      if (window.innerWidth < 1188) {
        this.$store.commit('ui/hideNavigation');
      } else {
        this.$store.commit('ui/showNavigation');
      }
    },
  },
  beforeCreate() {
    registerEmitter();
  },
  computed: {
    navigationHidden() {
      return this.$store.getters['ui/isNavigationHidden'];
    },
  },
};
</script>

<style lang="scss">
@import '@/style/main';

.grecaptcha-badge {
  visibility: hidden;
}

.mobile-only {
  display: none !important;

  @include small-down {
    display: block !important;
  }
}

.mobile-hide {
  @include small-down {
    display: none !important;
  }
}

@include small-down {
  .page,
  .page-v2 {
    margin-top: 50px !important;
  }
}
</style>
